.post-container {
    background-color: rgb(0, 255, 255);
    text-align: left;
    border-radius: 10px;
    box-shadow: 2px 2px 10px;
    margin: 40px;
}

.post-title {
    background-color: rgb(0, 217, 255);
    padding: 20px 30px;
    margin: 0;
    border-radius: 10px;
}

.post-author {
    padding: 10px 30px;
    margin: 0;
}

.post-content {
    background-color: aliceblue;
    padding: 15px 60px;
    margin: 0;
    min-height: 150px;
    border-radius: 10px;
}