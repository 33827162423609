.App {
  text-align: center;
  background-color: rgb(241, 251, 255);
}

header {
  background-color: aqua;
  text-align: left;
}

#main-chirps {
  text-align: center;
  display: inline-block;
  width: 60vw;
  min-width: 500px;
}

h1 {
  margin: 0;
  padding: 5px 50px;
}

ul {
  list-style-type: none;
  padding: 0;
}

#post-chirp {
  background-color: aqua;
  margin: 40px;
  box-shadow: 2px 2px 10px;
  border-radius: 10px;
  padding: 0px;
}

#post-chirp h2 {
  background-color: rgb(0, 217, 255);
  padding: 20px 30px;
  margin: 0;
  border-radius: 10px;
}

form {
  padding: 10px;
}

#form-title, #form-content, #form-name {
  background-color: rgb(238, 253, 253);
  display: block;
  margin: auto;
  width: 90%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  border-width: 2px;
  border-style: inset;
  border-color: aqua;
}

#form-title {
  height: 20px;
  font-size: 1.2em;
}

#form-content {
  height: 50px;
  resize: none;
}

#form-name {
  height: 20px;
}

#submit-div {
  text-align: right;
  width: 92%;
  display: block;
  margin: auto;
}

#form-submit {
  background-color: rgb(0, 217, 255);
  width: 30%;
  height: 30px;
  border-color: aqua;
}

#error {
  color: red;
  font-size: 12px;
  text-align: left;
  padding: 0px 20px;
}